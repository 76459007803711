.user-container {
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
}

.img-user {
  border-radius: 10px;
  object-fit: cover;
}

.form-user {
  gap: 5px;
}

.label-user {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon-user {
  cursor: pointer;
}
