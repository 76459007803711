.circularchart-featured {
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;

}
.circularchart-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: gray;
}
.circularchart-title {
  font-size: 16px;
  font-weight: 500;
}

.circularchart-bottom {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.circularchart-featuredChart {
  width: 150px;
  height: 150px;
}

.circularchart-title {
  font-weight: 500;
  color: gray;
}

.circularchart-amount {
  font-size: 30px;
}

.circularchart-desc {
  font-weight: 300;
  font-size: 12px;
  color: gray;
  text-align: center;
}

.circularchart-summary {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.circularchart-item {
  text-align: center;
}
.circularchart-itemTitle {
  font-size: 14px;
  color: gray;
}

.circularchart-itemResult {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
}
.circularchart-positive {
  color: green;
}

.circularchart-negative {
  color: red;
}
