.dashboardTable {
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    border-radius: 10px;
    color: gray;
  }

  .dashboardTable-status {
    padding: 5px;
    border-radius: 5px;
  }

  .dashboardTable-status-Paid {
    text-align: center;
    font-weight: bold;
    min-width: 75px;
    background-color: #b3cf99;
  }

  .dashboardTable-status-Pending {
    text-align: center;
    font-weight: bold;
    min-width: 75px;
    background-color: #f1ee8e;
  }
  
  .dashboardTable-status-Placed {
    text-align: center;
    font-weight: bold;
    min-width: 75px;
    background-color: #f1ee8e;
  }

  .dashboardTable-status-Accepted {
    min-width: 75px;
    text-align: center;
    font-weight: bold;
    color: white;
    background-color: #b3cf99;
  }

  .dashboardTable-status-Rejected {
    min-width: 75px;
    text-align: center;
    font-weight: bold;
    color: white;
    background-color: #ee6b6e;
  }

  .dashboardTable-status-Shipped {
    min-width: 75px;
    text-align: center;
    font-weight: bold;
    color: white;
    background-color: #ca5cdd;
  }

  .dashboardTable-status-Delivered {
    min-width: 75px;
    text-align: center;
    font-weight: bold;
    color: white;
    background-color: #64b7f6;
  }