.widget {
    display: flex;
    justify-content: space-between;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
}
  
.widget-link {
    width: max-content;
    font-size: 12px;
    cursor: pointer;
}
  
.widget-icon {
    font-size: 35px !important;
    padding: 5px;
    border-radius: 5px;
    align-self: flex-end;
}
  