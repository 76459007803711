
.category-container {
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
  }

  .category-form-container{
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
  }
  
  .img-category {
    border-radius: 50%;
    object-fit: cover;
  }
  
  .form-category {
    gap: 5px;
  }
  
  .label-category {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .icon-category {
    cursor: pointer;
  }
  