.userdetails {
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  border-radius: 10px;
  color: gray;
}

.userdetails-itemImg {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
}

.userdetails-detailItem {
  margin-bottom: 10px;
  font-size: 14px;
}

.userdetails-itemKey {
  font-weight: bold;
  color: gray;
  margin-right: 5px;
}
